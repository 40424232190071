@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
  }
}
trix-editor ol li {
  list-style: decimal;
  margin-left: 1em;
}
trix-editor ul li {
  list-style: disc;
  margin-left: 1em;
}

trix-editor h1 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

trix-editor blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

trix-editor blockquote::before {
  content: "\201C" !important;
}

trix-editor blockquote::after {
  content: "\201C" !important;
}
trix-editor pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  overflow: auto;
  white-space: pre;
  margin: 1em 0px;
}

trix-editor a {
  outline: none;
  text-decoration: underline;
  color: blue;
  padding: 2px 1px 0;
}

trix-editor a:link {
  color: #265301;
}

trix-editor a:visited {
  color: #437a16;
}

trix-editor a:focus {
  border-bottom: 1px solid;
  background: #bae498;
}

trix-editor a:hover {
  border-bottom: 1px solid;
  background: #cdfeaa;
}

trix-editor a:active {
  background: #265301;
  color: #cdfeaa;
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.heading-text {
  font-size: "20px";
  line-height: "32px";
}

.score-number {
  font-size: "36px";
  line-height: 1.3;
  color: #009d79;
}

.color-text {
  color: #94a3b8;
}

.score-color {
  color: #1c1f2b;
}
