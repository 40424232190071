.App {
  background-color: #f7f9fc;
  min-height: 110vh;
  padding-bottom: 50px;
}

a,
.pointer {
  cursor: pointer !important;
}

.pb-250 {
  padding-bottom: 250px !important;
}
